import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../shared/components/Input';
import Button from '../../../../shared/components/Button';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import { useNotification } from '../../../../shared/helpers/notification';
import Checkbox from '../../../../shared/components/Checkbox';
import { emailValidator } from '../../../../shared/components/Input/validators';
import Loader from '../../components/Loader';
import { businessTypeParser, saveClientTokensLocalStorage } from './utils';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { useKeyDown } from '../../../../shared/helpers/hooks';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { api, setTokens } = useApi();
  const { showNotification } = useNotification();

  const [inputErrors, setInputErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({ login: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);

  const readOnly = Object.values(form).some((value) => !value) || inputErrors || isLoading;

  const handleForm = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const navigateHandler = (path = -1) => {
    navigate(path);
  };

  const remindPasswordNavigateHandler = () => {
    navigateHandler('/remindPassword');
  };

  const handleSignIn = async () => {
    setIsLoading(true);
    try {
      const parsedForm = { ...form, login: form.login.toLowerCase() };
      const { data } = await api.post('/auth/login', parsedForm);
      const { token, refreshToken, session } = data;
      const { id, login, businessProfiles, subscriptions, isManager } = session;
      setTokens({ token, refreshToken });

      const profileWithBusinessType = businessTypeParser(businessProfiles, subscriptions);
      setAuth({ isAuth: true, id, login, businessProfiles: profileWithBusinessType, isAdmin: false, isManager });
      saveClientTokensLocalStorage({ token, refreshToken });
      navigateHandler('/dashboard');
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  useKeyDown({ key: 'Enter', callback: handleSignIn, canInvoke: !readOnly });

  const handleInputError = (value) => {
    setInputErrors(Boolean(value));
  };

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loading}>
          <Loader />
        </div>
      )}
      <div className={classes.form}>
        <span className={classes.text}>{t('login.signIn')}</span>
        <div className={classes.inputWrapper}>
          <Input
            onError={handleInputError}
            showErrorAfterBlur
            validator={emailValidator}
            className={classes.input}
            value={form.login}
            label={t('login.login')}
            onChange={handleForm('login')}
            transformer={(v) => v.toLowerCase()}
          />
          <div className={classes.subInfoBox}>{t('login.subInfo')}</div>
          <Input
            showErrorAfterBlur
            className={classes.input}
            value={form.password}
            label={t('login.password')}
            onChange={handleForm('password')}
            type={showPassword ? 'text' : 'password'}
          />
        </div>
        <Button
          label={t('login.remindPassword')}
          onClick={remindPasswordNavigateHandler}
          idName={classes.remindPassword}
        />
        <Checkbox
          onClick={() => setShowPassword((prev) => !prev)}
          value={showPassword}
          checkboxStyle={classes.checkboxStyle}
          labelPosition="right"
          className={classes.checkbox}
          label={t('login.showPassword')}
        />
        <div className={classes.actionBoxContainer}>
          <Button idName={classes.signInButton} label={t('login.signIn')} onClick={handleSignIn} readOnly={readOnly} />
          <Button idName={classes.goBack} label={t('login.goBack')} onClick={() => navigateHandler()} />
        </div>
      </div>
    </div>
  );
};

export default Login;
