import React, { useState } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Calendar from '../Calendar';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';

const DateInput = ({
  minDate,
  maxDate,
  selectPeriodAllowed,
  twoMonths,
  selectOnDateClick = !twoMonths,
  label,
  placeholder,
  className,
  onChange,
  userId,
  admin,
  value: dateInputValue = '',
  dateFrom,
  dateTo = dateFrom,
  messageForDateAfterMaxDate,
}) => {
  const [selectedDate, setSelectedDate] = useState({ dateFrom, dateTo });
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateChange = (value) => {
    setSelectedDate(value);
    onChange(value);
    setShowCalendar(false);
    if (selectOnDateClick) {
      setShowCalendar(false);
    }
  };

  const onClose = () => {
    setShowCalendar(false);
    setSelectedDate({ dateFrom: '', dateTo: '' });
    if (twoMonths) {
      onChange({ dateFrom: '', dateTo: '' });
    } else {
      onChange({ date: '' });
    }
  };

  const handleCalendarClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      onClick={() => setShowCalendar(true)}
      className={clsx(classes.wrapper, admin && classes.adminWrapper, className)}
    >
      <div className={classes.inputWrapper}>
        <div className={classes.label}>{placeholder}</div>
        <div className={classes.placeholder}>{dateInputValue?.length ? dateInputValue : ''}</div>
      </div>
      <CalendarIcon className={clsx(classes.icon, admin && classes.adminIconColor)} />
      {showCalendar && (
        <div onClick={handleCalendarClick} className={classes.calendarWrapper}>
          <Calendar
            className={clsx(userId && classes.calendarWithUser)}
            maxDate={maxDate}
            minDate={minDate}
            oneDayChoose={!selectPeriodAllowed}
            isSingleMonth={!twoMonths}
            quickSelect={twoMonths}
            onClose={onClose}
            onChange={handleDateChange}
            selectedDate={selectedDate?.dateFrom || selectedDate?.date}
            selectedEndPeriodDate={selectedDate?.dateTo || selectedDate?.date}
            admin={admin}
            selectOnDateClick={selectOnDateClick}
            messageForDateAfterMaxDate={messageForDateAfterMaxDate}
          />
        </div>
      )}
    </div>
  );
};

export default DateInput;
