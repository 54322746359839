import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import Input from '../../../../../shared/components/Input';
import ActionBox from '../ActionBox';
import { makeRequired, required } from '../../../../../shared/components/Input/validators';
import { StateEnum } from '../../../../../shared/helpers/state';
import classes from './styles.module.css';
import { GOOGLE_BUSINESS_TITLE_MAX_LENGTH } from '../../../helpers/constants';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const NameForm = ({
  className,
  form,
  setForm,
  onError,
  businessProfileId,
  setState,
  readOnlyAction,
  isEdit,
  editHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [name, setName] = useState('');

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);
      await api.post('/business_profile/updateBusinessProfileTitle', {
        businessProfileId,
        title: name,
      });
      showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setForm((prev) => ({ ...prev, title: name }));
    editHandler();
  };

  const handleCancel = () => {
    editHandler();
  };

  useEffect(() => {
    if (!isEdit) {
      setName(form.title);
    }
  }, [isEdit, form.title]);

  return (
    <div className={classes.wrapper}>
      <Input
        label={t('businessProfileEdit.nameCompany')}
        readOnly={!isEdit}
        value={name}
        onBlur={setName}
        validator={makeRequired(required(1))}
        onError={onError}
        maxLength={GOOGLE_BUSINESS_TITLE_MAX_LENGTH}
        showErrorAfterBlur
        transformer={(v) => String(v).trim()}
        autoComplete="off"
      />
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />}
    </div>
  );
};

export default NameForm;
