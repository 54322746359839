import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Calendar from '../Calendar';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';
import { useDashboard } from '../../../helpers/dashboard';
import { readOnlySubList } from '../../../helpers/constants';
import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';

const Header = ({ onPeriodSelect, status, period }) => {
  const { t } = useTranslation();
  const [openCalendar, setOpenCalendar] = useState(false);
  const { businessProfile } = useDashboard();
  const { subscription } = businessProfile;

  const isReadOnlyBp = readOnlySubList.includes(subscription);
  const isVerified = [PROFILE_STATE_ENUM.verified, PROFILE_STATE_ENUM.processing].includes(status);
  const isPeriodSelected = period !== t('dashboard.last30Days');
  const isChandePeriodBtnRendered = isVerified && !isReadOnlyBp;

  const toggleCalendar = () => {
    setOpenCalendar((prev) => !prev);
  };

  return (
    <div className={clsx(classes.wrapper, isPeriodSelected && classes.flexDirCol)}>
      <div className={classes.header}>{t('dashboard.dashboard')}</div>
      <div className={classes.actions}>
        <div>
          {isChandePeriodBtnRendered && (
            <Button
              onClick={toggleCalendar}
              Icon={CalendarIcon}
              iconStyle={classes.calendarIcon}
              className={classes.openCalendar}
              label={period}
            />
          )}
          {openCalendar && (
            <div>
              <Calendar onCancel={toggleCalendar} onPeriodChange={onPeriodSelect} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
