import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import BusinessOpinionItem from '../BusinessOpinionItem';
import Loader from '../../../components/Loader';
import { PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { subscriptionsConfig } from '../../../helpers/constants';
import EmptyState from '../../../components/EmptyState';

const BusinessOpinions = ({ data = [], profileStatus, adminPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToOpinions = () => navigate('/dashboard/opinions');
  const {
    businessProfile: { subscription },
  } = useDashboard();
  const isFreeSubPlan = subscription === subscriptionsConfig.free;

  const isBusinessNotVerified = ![PROFILE_STATE_ENUM.verified, PROFILE_STATE_ENUM.processing].includes(profileStatus);
  const renderShowAllOpinionsButton = Boolean(data.length) && !adminPreview && !isFreeSubPlan;

  if (!data) return <Loader />;

  return (
    <div className={clsx(classes.opinions, isBusinessNotVerified && classes.noData)}>
      {Boolean(data.length) && (
        <div className={classes.cardHeader}>
          <div className={classes.title}>{t('dashboard.newestOpinions')}</div>
        </div>
      )}
      {data.length ? (
        <div className={classes.opinionList}>
          {data.map((review) => (
            <BusinessOpinionItem key={review.id} review={review} />
          ))}
        </div>
      ) : (
        <div className={classes.emptyState}>
          <EmptyState title={t('opinions.noOpinionsYet')} className={classes.emptyOpinionState} />
          <Button
            onClick={navigateToOpinions}
            label={t('dashboard.goToOpinions')}
            className={classes.goToOpinionsBtn}
            readOnly={isBusinessNotVerified}
          />
        </div>
      )}
      {renderShowAllOpinionsButton && (
        <Button
          onClick={navigateToOpinions}
          label={t('dashboard.showAllOpinions')}
          className={classes.showOpinionsBtn}
          readOnly={isBusinessNotVerified}
        />
      )}
    </div>
  );
};

export default BusinessOpinions;
