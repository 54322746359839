import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Select from '../../../../../shared/components/Select';
import { actionTypeList, getTranslatedCollection, keyChangeList } from './utils';
import DateInput from '../../../../../shared/components/DateInput';
import { TODAY } from '../../../../../shared/helpers/const';
import { formatDate } from '../../../../../shared/helpers/helpers';

const ChangeHistoryActions = ({ onFiltersChange, userId, fiters }) => {
  const { t } = useTranslation();

  const translatedActionTypeList = getTranslatedCollection(actionTypeList);
  const translatedKeyChangeList = getTranslatedCollection(keyChangeList);

  const [selectedActionType, setSelectedActionType] = useState({
    id: translatedActionTypeList[0].id,
    name: translatedActionTypeList[0].name,
  });
  const [selectedKey, setSelectedKey] = useState({
    id: translatedKeyChangeList[0].id,
    name: translatedKeyChangeList[0].name,
  });

  const handleDateChange = (value) => {
    onFiltersChange('date')(value.date);
  };

  const handleActionTypeChange = (action) => {
    const selectedAction = translatedActionTypeList.find((item) => item.id === action);
    setSelectedActionType(selectedAction);
    onFiltersChange('action')(selectedAction.id === 'ALL' ? '' : selectedAction.id);
  };

  const handleKeyChange = (key) => {
    setSelectedKey(translatedKeyChangeList.find((item) => item.id === key));
    onFiltersChange('key')(key);
  };

  const filterKeyList = ({ list, value }) => list.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

  return (
    <div className={classes.wrapper}>
      <DateInput
        className={classes.datInput}
        onChange={handleDateChange}
        label={t('global.date')}
        placeholder={t('global.chooseDate')}
        userId={userId}
        minDate={dayjs().subtract(2, 'years')}
        maxDate={TODAY}
        selectOnDateClick
        value={formatDate(fiters.date)}
      />
      <Select
        list={translatedKeyChangeList}
        label={t('businessProfileChangeHistory.editedField')}
        className={classes.select}
        filterList={filterKeyList}
        onSelect={handleKeyChange}
        value={selectedKey.id}
      />
      <Select
        onSelect={handleActionTypeChange}
        list={translatedActionTypeList}
        label={t('businessProfileChangeHistory.actionType')}
        className={classes.select}
        value={selectedActionType.id}
      />
    </div>
  );
};

export default ChangeHistoryActions;
